


































































































































































































































































































































import { Vue, Component, Prop, PropSync, VModel } from "vue-property-decorator";
import {
  DoctorSpecialty,
  Event,
  EventCycle,
  EventFormEnum,
  EventStatusEnum,
  PaymentTypeEnum,
} from "@/generated/graphql";
import Table from "@components/parts/tables/Table.vue";
import { Routes } from "@/types/core";
import {
  eventForms,
  eventFormsNames,
  eventStatuses,
  eventStatusesNames,
  paymentTypes,
  paymentTypesNames,
} from "@/core/static/dict";
import { currency, date } from "@/core/filters";
import { DateTime } from "luxon";
import { TableHeader } from "@/components/parts/tables/Settings.vue";

interface TypeField {
  id: number | string;
}

@Component({
  components: { Table },
})
export default class EventCyclesTable extends Vue {
  @Prop({ type: Array, default: () => [] }) items!: EventCycle[];
  @Prop({ type: String, default: "" }) label!: string;
  @Prop({ type: Boolean, default: false }) showSelect!: boolean;
  @VModel({ type: Array, default: () => [] }) selected!: unknown[];
  @PropSync("searchValue", { type: String, default: "" }) search!: string;

  protected readonly EventStatusEnum = EventStatusEnum;
  protected readonly eventStatusesNames = eventStatusesNames;
  protected readonly eventStatuses = eventStatuses;

  protected readonly EventFormEnum = EventFormEnum;
  protected readonly eventFormsNames = eventFormsNames;
  protected readonly eventForms = eventForms;

  protected readonly PaymentTypeEnum = PaymentTypeEnum;
  protected readonly paymentTypesNames = paymentTypesNames;
  protected readonly paymentTypes = paymentTypes;

  protected readonly Routes = Routes;
  protected readonly filter: {
    id: string;
    publicName: string;
    privateName: string;
    price: string;
    city: string;
    dateFrom: string;
    dateTo: string;
    specialties: string;
    amountNmo: string;
    eventStatus: string;
    lecturers: string;
    eventForm: EventFormEnum | null;
    paymentType: PaymentTypeEnum | null;
  } = {
    id: "",
    publicName: "",
    privateName: "",
    eventForm: null,
    paymentType: null,
    price: "",
    city: "",
    dateFrom: "",
    dateTo: "",
    specialties: "",
    lecturers: "",
    amountNmo: "",
    eventStatus: "",
  };

  protected readonly headers: TableHeader[] = [
    { value: "id", text: "ID", sortable: false },
    {
      value: "public_name",
      text: "Общее название",
      hidden: false,
      sortable: false,
    },
    {
      value: "private_name",
      text: "Частное название",
      hidden: true,
      sortable: false,
    },
    {
      value: "event_form",
      text: "Форма проведения",
      width: 150,
      hidden: true,
      sortable: false,
    },
    {
      value: "payment_type",
      text: "Тип оплаты",
      width: 150,
      hidden: true,
      sortable: false,
    },
    // { value: "event_status", text: "Статус", hidden: true },
    {
      value: "lecturers",
      text: "Лекторы",
      sortable: false,
    },
    {
      value: "city",
      text: "Город",
      sortable: false,
    },
    {
      value: "date_from",
      text: "Дата начала",
      sortable: false,
    },
    {
      value: "date_to",
      text: "Дата окончания",
      sortable: false,
    },
    {
      value: "specialties",
      text: "Специальность",
      hidden: true,
      sortable: false,
    },
    // { value: "amount_nmo", text: "Баллы нмо", hidden: true },
    {
      value: "price",
      text: "Цена",
      sortable: false,
    },
    {
      value: "_actions",
      text: "Действия",
      sortable: false,
    },
  ];

  /**
   * Список отображаемых колонок
   * @protected
   */
  protected visibleHeadersList: string[] = [];

  /**
   * Преобразователи данных для экспорта в excel
   * @protected
   */
  protected get exportResolvers(): Partial<
    Record<keyof Event, CallableFunction>
  > {
    return {
      event_form: (item: EventCycle) =>
        String(this.eventFormsNames[item.event_form]),
      payment_type: (item: EventCycle) =>
        String(item.payment_type && this.paymentTypesNames[item.payment_type]),
      price: (item: EventCycle) => String(item.price && currency(+item.price)),
      // event_status: (item: EventCycle) =>
      //   String(item.event_status && eventStatusesNames[item.event_status]),
      city: (item: EventCycle) => String(item.city && item.city.name),
      date_from: (item: EventCycle) => date(item.date_from),
      date_to: (item: EventCycle) => date(item.date_to),
      specialties: (item: EventCycle) =>
        item.specialties?.map((s) => s.name).join(", "),
      lecturers: (item: EventCycle) =>
        item.lecturers
          ?.map((s) => [s.family_name, s.first_name, s.second_name].join(" "))
          .join(" "),
    };
  }

  /**
   * Геттер с отфильтрованными по колонкам строками
   * СТРОГОЕ сравнение AND
   * @protected
   */
  protected get filteredEvents(): EventCycle[] {
    const filter = this.filter;
    const conditions: CallableFunction[] = [];

    if (filter.id) conditions.push(this.filterId);
    if (filter.publicName) conditions.push(this.filterPublicName);
    if (filter.privateName) conditions.push(this.filterPrivateName);
    if (filter.eventForm) conditions.push(this.filterEventForm);
    if (filter.paymentType) conditions.push(this.filterPaymentType);
    if (filter.price) conditions.push(this.filterPrice);
    if (filter.city) conditions.push(this.filterCity);
    if (filter.dateFrom) conditions.push(this.filterDateFrom);
    if (filter.dateTo) conditions.push(this.filterDateTo);
    if (filter.lecturers) conditions.push(this.filterLecturers);
    // if (filter.eventStatus) conditions.push(this.filterEventStatus);
    // if (filter.amountNmo) conditions.push(this.filterAmountNMO);
    if (filter.specialties) conditions.push(this.filterSpecialties);

    if (conditions.length > 0) {
      return this.items.filter((item) =>
        conditions.every((cond) => cond(item))
      );
    } else return this.items;
  }

  private filterableItemField<T extends TypeField>(
    acc: Array<T>,
    curr: T
  ): Array<T> {
    const itemAcc = acc;
    if (!acc.length || acc.findIndex((el: T) => +el.id === +curr.id) === -1) {
      itemAcc.push(curr);
    }
    return itemAcc;
  }

  private eventCyclesSpecialtiesItem(item: EventCycle): Array<DoctorSpecialty> {
    return item?.specialties
      ? item.specialties.reduce(
          (acc: Array<DoctorSpecialty>, curr: DoctorSpecialty) => {
            return this.filterableItemField(acc, curr);
          },
          []
        )
      : [];
  }

  /**
   * БЛОК С ФУНКЦИЯМИ ФИЛЬТРАЦИИ ПО КОЛОНКАМ
   *
   * Каждый фильтр будет отклонен (вернет true) если его колонка скрыта настройками
   */

  private filterId(item: Event): boolean {
    if (!this.visibleHeadersList.includes("id")) return true;
    return item.id === this.filter.id;
  }

  private filterPublicName(item: EventCycle): boolean {
    if (!this.visibleHeadersList.includes("public_name")) return true;
    return item.public_name
      .toLowerCase()
      .includes(this.filter.publicName.toLowerCase());
  }

  private filterPrivateName(item: EventCycle): boolean {
    if (!this.visibleHeadersList.includes("private_name")) return true;
    return !!item.private_name
      ?.toLowerCase()
      .includes(this.filter.privateName.toLowerCase());
  }

  private filterEventForm(item: EventCycle): boolean {
    if (!this.visibleHeadersList.includes("event_form")) return true;
    return (
      item.event_form.toLowerCase() === this.filter.eventForm?.toLowerCase()
    );
  }

  private filterPaymentType(item: EventCycle): boolean {
    if (!this.visibleHeadersList.includes("payment_type")) return true;
    return (
      item.payment_type.toLowerCase() === this.filter.paymentType?.toLowerCase()
    );
  }

  private filterPrice(item: EventCycle): boolean {
    if (!this.visibleHeadersList.includes("price")) return true;
    return item.price === +this.filter.price;
  }

  private filterCity(item: EventCycle): boolean {
    if (!this.visibleHeadersList.includes("city")) return true;
    return (item.city?.name.toLowerCase() || "").includes(
      this.filter.city.toLowerCase()
    );
  }

  private filterDateFrom(item: EventCycle): boolean {
    if (!this.visibleHeadersList.includes("date_from")) return true;
    const date = DateTime.fromISO(item.date_from).set({
      hour: 0,
      minute: 0,
      second: 0,
    });
    const filterDate = DateTime.fromISO(this.filter.dateFrom).set({
      hour: 0,
      minute: 0,
      second: 0,
    });
    return date.equals(filterDate);
  }

  private filterDateTo(item: EventCycle): boolean {
    if (!this.visibleHeadersList.includes("date_to")) return true;
    const date = DateTime.fromISO(item.date_to).set({
      hour: 0,
      minute: 0,
      second: 0,
    });
    const filterDate = DateTime.fromISO(this.filter.dateTo).set({
      hour: 0,
      minute: 0,
      second: 0,
    });
    return date.equals(filterDate);
  }

  private filterSpecialties(item: EventCycle): boolean {
    if (!this.visibleHeadersList.includes("specialties")) return true;
    return !!item.specialties
      ?.map((s) => s.name)
      .join(" ")
      .toLowerCase()
      .includes(this.filter.specialties.toLowerCase());
  }

  private filterLecturers(item: EventCycle): boolean {
    if (!this.visibleHeadersList.includes("lecturers")) return true;
    return !!item.lecturers
      ?.map((s) => [s.family_name, s.first_name, s.second_name].join(" "))
      .join(" ")
      .toLowerCase()
      .includes(this.filter.lecturers.toLowerCase());
  }

  // private filterAmountNMO(item: EventCycle): boolean {
  //   if (!this.visibleHeadersList.includes("amount_nmo")) return true;
  //   return item.amount_nmo === +this.filter.amountNmo;
  // }
  //
  // private filterEventStatus(item: EventCycle): boolean {
  //   if (!this.visibleHeadersList.includes("event_status")) return true;
  //   return (
  //     item.event_status?.toLowerCase() === this.filter.eventStatus.toLowerCase()
  //   );
  // }
}
