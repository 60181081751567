









































































































































































import { Component, Prop, VModel, Watch } from "vue-property-decorator";
import { Routes } from "@/types/core";
import ListView from "@/core/UI/Views/ListView";
import { CycleEventsList } from "@/graphql/queries/Events.graphql";
import {
  CycleEventsQueryVariables,
  DoctorSpecialty,
  EventFormEnum,
  EventStatusEnum,
  InputCycleEventsEnum,
  PaymentTypeEnum,
  QueryCycleEventsHasSpecialtiesColumn,
  QueryCycleEventsHasSpecialtiesWhereHasConditions,
  SqlOperator,
  EventCategoryEnum,
  EventsListsQueryVariables,
  QueryEventsOrderByColumn,
  SortOrder,
  CycleEventsListQueryVariables,
  QueryCycleEventsOrderByColumn,
} from "@/generated/graphql";
import {
  eventForms,
  eventStatuses,
  paymentTypes,
  eventCategories,
} from "@/core/static/dict";
import { Debounce } from "vue-debounce-decorator";

import EventCyclesTable from "@/components/parts/tables/EventCyclesTable.vue";
import SpecialtiesAutocomplete from "@/components/widgets/commons/Inputs/SpecialtiesAutocomplete.vue";
import { DateTime } from "luxon";
import { merge } from "lodash";

const initialFilter: {
  eventForm: EventFormEnum[];
  search: string;
  category: EventCategoryEnum[];
} = {
  search: "",
  eventForm: [],
  category: [],
};

const initialAdditionalFilters: {
  specialties: DoctorSpecialty[];
  eventStatus: EventStatusEnum[];
  paymentType: PaymentTypeEnum[];
  dateFrom: string;
  dateTo: string;
} = {
  specialties: [],
  paymentType: [],
  eventStatus: [],
  dateFrom: "",
  dateTo: "",
};

@Component({
  components: {
    EventCyclesTable,
    SpecialtiesAutocomplete,
  },
})
export default class CyclesList extends ListView {
  @Prop({ type: Object, default: () => ({}) })
  extraParams!: CycleEventsQueryVariables;

  @Prop({ type: Boolean, default: false }) showSelect!: boolean;

  @VModel({ type: Array, default: () => [] }) selected!: Event[];

  protected readonly fetchListQuery = CycleEventsList;
  protected readonly fetchKey: string = "cycleEvents";
  protected readonly Routes: typeof Routes = Routes;

  protected readonly eventForms = eventForms;
  protected readonly paymentTypes = paymentTypes;
  protected readonly eventStatuses = eventStatuses;
  protected readonly eventCategories = eventCategories;
  protected readonly initialFilter = initialFilter;
  protected filters = { ...initialFilter };

  protected additionalFiltersVisible = false;
  protected additionalFilter = { ...initialAdditionalFilters };
  protected readonly initialAdditionalFilters = initialAdditionalFilters;

  protected get fetchListVariables(): CycleEventsListQueryVariables {
    const orderBy: CycleEventsListQueryVariables["orderBy"] = [
      { column: QueryCycleEventsOrderByColumn.Id, order: SortOrder.Desc },
    ];

    const where: CycleEventsQueryVariables["where"] = {
      column: InputCycleEventsEnum.IsArchive,
      operator: SqlOperator.Eq,
      value: false,
      AND: [],
    };

    if (this.filters.search.length) {
      where.AND?.push({
        OR: [
          InputCycleEventsEnum.PrivateName,
          InputCycleEventsEnum.PublicName,
        ].map((field) => ({
          column: field,
          operator: SqlOperator.Ilike,
          value: `%${this.filters.search}%`,
        })),
      });
    }

    if (this.filters.eventForm?.length) {
      where.AND?.push({
        column: InputCycleEventsEnum.EventForm,
        operator: SqlOperator.In,
        value: this.filters.eventForm.map((f) => f.toLowerCase()),
      });
    }

    if (this.filters.category?.length) {
      where.AND?.push({
        column: InputCycleEventsEnum.EventCategory,
        operator: SqlOperator.In,
        value: this.filters.category.map((f) => f.toLowerCase()),
      });
    }

    if (this.additionalFilter.eventStatus.length) {
      where.AND?.push({
        column: InputCycleEventsEnum.Status,
        operator: SqlOperator.In,
        value: this.additionalFilter.eventStatus.map((f) => f.toLowerCase()),
      });
    }

    if (this.additionalFilter.paymentType.length) {
      where.AND?.push({
        column: InputCycleEventsEnum.PaymentType,
        operator: SqlOperator.In,
        value: this.additionalFilter.paymentType.map((p) => p.toLowerCase()),
      });
    }

    if (this.additionalFilter.dateFrom) {
      where.AND?.push({
        column: InputCycleEventsEnum.DateFrom,
        operator: SqlOperator.Gte,
        value: DateTime.fromISO(this.additionalFilter.dateFrom).toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }),
      });
    }

    if (this.additionalFilter.dateTo) {
      where.AND?.push({
        column: InputCycleEventsEnum.DateTo,
        operator: SqlOperator.Lte,
        value: DateTime.fromISO(this.additionalFilter.dateTo).toISO({
          suppressMilliseconds: true,
          includeOffset: false,
        }),
      });
    }

    const hasSpecialties: QueryCycleEventsHasSpecialtiesWhereHasConditions = {};
    if (this.additionalFilter.specialties.length > 0) {
      hasSpecialties.column = QueryCycleEventsHasSpecialtiesColumn.Id;
      hasSpecialties.operator = SqlOperator.In;
      hasSpecialties.value = this.additionalFilter.specialties;
    }

    return merge(
      {
        where,
        orderBy,
        page: 1,
        ...(this.additionalFilter.specialties.length > 0
          ? { hasSpecialties }
          : {}),
      },
      this.extraParams
    );
  }

  @Watch("filters.search")
  @Debounce(500)
  private search(): void {
    this.refetch();
  }
}
